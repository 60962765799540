import HttpClient from './HttpClient'

class SmartFlowApiClient extends HttpClient {

    constructor(baseURL, token, tenant) {
      super({
        baseURL,
        token
      });
      this.tenant = tenant;
    }

    get userProfile() {
      return {
        get: () => this.get('users/me')
      };
    }

    get customers() {
      return {
        get: (criteria) => {
          return this.get(`${this.tenant}/customers${this.createODataQuery(criteria)}`);
        },
        getById: (id) => {
          return this.get(`${this.tenant}/customers/${id}`);
        },
        post: (data) => {
          return this.post(`${this.tenant}/customers`, data);
        },
        put: (data) => {
          return this.put(`${this.tenant}/customers/${data.id}`, data);
        }
      };
    }

    get standards() {
      return {
        get: (criteria) => {
          const query = this.createODataQuery(criteria);
          return this.get(`${this.tenant}/standards${query}`);
        },
        getById: (data) => {
          return this.get(`${this.tenant}/standards/${data.id}?customer=${data.isCustomer = false}`);
        },
        post: (data) => this.post(`${this.tenant}/standards`, data),
        put: (id,data) => this.put(`${this.tenant}/standards/${id}`, data),
        getEligibleToAssign: (data,criteria) => {
          const query = this.createODataQuery(criteria);
          const eligibleStandardCriteria = this.eligibleStandardCriteria(data);
          return this.get(`${this.tenant}/standards/eligible-to-assign${query}${eligibleStandardCriteria}`);
        }
      }
    }
   
    get auditEvents() {
      return {
        get: (criteria) => this.get(`${this.tenant}/audit-events${this.createODataQuery(criteria)}`)
      }
    }

  
    get orders() {
      return {
        get: (criteria) => this.get(`${this.tenant}/orders${this.createODataQuery(criteria)}`),
        post: (data) => this.post(`${this.tenant}/orders`, data),
        getById: (id, customer, standard,includeBuyerAddress,includeConsigneeAddress,includeConsigneeBankAccount,includeNotifyContact) => this.get(`${this.tenant}/orders/${id}?customer=${customer}&standard=${standard}&includeBuyerAddress=${includeBuyerAddress}&includeConsigneeAddress=${includeConsigneeAddress}&includeConsigneeBankAccount=${includeConsigneeBankAccount}&includeNotifyContact=${includeNotifyContact}`),
        put: (id, data) => this.put(`${this.tenant}/orders/${id}`, data),
        confirmOrder: (id) => this.put(`${this.tenant}/orders/${id}/confirm`)
      }
    }

    get users() {
      return {
        get: (criteria) => this.get(`${this.tenant}/users${this.createODataQuery(criteria)}`)
      }
    }    

    get stockItems() {
      return {
        get: (criteria) => this.get(`${this.tenant}/stock-items${this.createODataQuery(criteria)}`),
        upload: (data) => this.upload("POST", `${this.tenant}/stock-items/file/excel`, data),
        getDeliveryOrderDocument: (id) => this.getFile(`${this.tenant}/stock-items/${id}/broker-delivery-order`),
        uploadDeliveryOrderDocument: (id,data) => this.upload("POST", `${this.tenant}/stock-items/${id}/broker-delivery-order`,data),
        getStockItemsForCollection: (criteria) => this.get(`${this.tenant}/stock-items/collections${this.createODataQuery(criteria)}`)
      }
    }

    get stockItemUnits() {
      return {
          activate: (data) => this.post(`${this.tenant}/stock-item-units/activate`, data),
          adjustQuantity: (id, data) => this.put(`${this.tenant}/stock-item-units/${id}/adjust-quantity`, data)
      }
  }

  get suppliers() {
    return {
      get: (criteria) => this.get(`${this.tenant}/suppliers${this.createODataQuery(criteria)}`),
      post:(data) => this.post(`${this.tenant}/suppliers`, data),
      put: (id, data) => this.put(`${this.tenant}/suppliers/${id}`, data),
      getById: (id) => this.get(`${this.tenant}/suppliers/${id}`)
    };
  }

  get buyingPlans() {
    return {
      post:(data) => this.post(`${this.tenant}/buying-plans/data`, data)
    };
  }

  get purchaseRequestItems() {
    return {
      get: (criteria) => this.get(`${this.tenant}/purchase-request-items${this.createODataQuery(criteria)}`),
    };
  }

    get types() {
      return {
        get: (type) => this.get(`${this.tenant}/types/${type}`)
      }
    }

    get companyProfiles() {
      return {
        get: (criteria) => this.get(`${this.tenant}/company-profiles${this.createODataQuery(criteria)}`),
        post: (data) => this.post(`${this.tenant}/company-profiles`, data),
        put: (id, data) => this.put(`${this.tenant}/company-profiles/${id}`, data),
        uploadLogo: (id, data) => this.upload("PUT", `${this.tenant}/company-profiles/${id}/logo`, data)
      }
    }

    get customerBankAccounts() {
      return {
        get: (customerId, criteria) => this.get(`${this.tenant}/customers/${customerId}/bank-accounts${this.createODataQuery(criteria)}`),
        post: (customerId, data) => this.post(`${this.tenant}/customers/${customerId}/bank-accounts`, data),
        put: (customerId, id, data) => this.put(`${this.tenant}/customers/${customerId}/bank-accounts/${id}`, data),
        delete: (customerId, id) => this.delete(`${this.tenant}/customers/${customerId}/bank-accounts/${id}`)
      }
    }

    get customerContacts() {
      return {
        get: (customerId, criteria) => this.get(`${this.tenant}/customers/${customerId}/contacts${this.createODataQuery(criteria)}`),
        post: (customerId, data) => this.post(`${this.tenant}/customers/${customerId}/contacts`, data),
        put: (customerId, id, data) => this.put(`${this.tenant}/customers/${customerId}/contacts/${id}`, data),
        delete: (customerId, id) => this.delete(`${this.tenant}/customers/${customerId}/contacts/${id}`)
      }
    }

    get companyContacts() {
      return {
         get: (companyProfileId, criteria) => this.get(`${this.tenant}/company-profiles/${companyProfileId}/contacts/${this.createODataQuery(criteria)}`),
         post: (companyProfileId, data ) => this.post(`${this.tenant}/company-profiles/${companyProfileId}/contacts/`, data),
         put: (companyProfileId, id, data) => this.put(`${this.tenant}/company-profiles/${companyProfileId}/contacts/${id}`, data),
         delete: (companyProfileId, id) => this.delete(`${this.tenant}/company-profiles/${companyProfileId}/contacts/${id}`)
      }
    }

    get companyAddresses() {
      return{
        get: (companyId, criteria) => this.get(`${this.tenant}/company-profiles/${companyId}/addresses${this.createODataQuery(criteria)}`),
        post: (companyId, data) => this.post(`${this.tenant}/company-profiles/${companyId}/addresses`, data),
        put: (companyId, id, data) => this.put(`${this.tenant}/company-profiles/${companyId}/addresses/${id}`, data) 
      }
    }

    get companyBankAccounts() {
      return{
        get: (companyId, criteria) => this.get(`${this.tenant}/company-profiles/${companyId}/bank-accounts${this.createODataQuery(criteria)}`),
        post: (companyId, data) => this.post(`${this.tenant}/company-profiles/${companyId}/bank-accounts`, data),
        put: (companyId, id, data) => this.put(`${this.tenant}/company-profiles/${companyId}/bank-accounts/${id}`, data),
        delete: (companyId, id) => this.delete(`${this.tenant}/company-profiles/${companyId}/bank-accounts/${id}`)
      }
    }

    get countries() {
      return {
        get: (criteria) => this.get(`${this.tenant}/countries${this.createODataQuery(criteria)}`)
      }
    }

    get customerAddresses() {
      return {
        get: (customerId, criteria) => this.get(`${this.tenant}/customers/${customerId}/addresses${this.createODataQuery(criteria)}`),
        post: (customerId, data) => this.post(`${this.tenant}/customers/${customerId}/addresses`, data),
        put: (customerId, id, data) => this.put(`${this.tenant}/customers/${customerId}/addresses/${id}`, data),
      }
    }

    get productCertifications() {
      return {
          get: (criteria) => this.get(`${this.tenant}/product-certifications${this.createODataQuery(criteria)}`),
          getById: (id) => this.get(`${this.tenant}/product-certifications/${id}`),
          post: (data) => this.post(`${this.tenant}/product-certifications`, data),
          put: (id, data) => this.put(`${this.tenant}/product-certifications/${id}`, data)
      };
  }

  get productCountryOrigins() {
      return {
          get: (criteria) => this.get(`${this.tenant}/product-country-origins${this.createODataQuery(criteria)}`),
          getById: (id) => this.get(`${this.tenant}/product-country-origins/${id}`),
          post: (data) => this.post(`${this.tenant}/product-country-origins`, data),
          put: (id, data) => this.put(`${this.tenant}/product-country-origins/${id}`, data)
      };
  }

  get products() {
      return {
          get: (criteria) => this.get(`${this.tenant}/products${this.createODataQuery(criteria)}`),
          getById: (id) => this.get(`${this.tenant}/products/${id}`),
          post: (data) => this.post(`${this.tenant}/products`, data),
          put: (id, data) => this.put(`${this.tenant}/products/${id}`, data),
          putDesignDetail: (id, data) => this.put(`${this.tenant}/products/${id}/design-details`, data),
          getDesignDetail: (id) => this.get(`${this.tenant}/products/${id}/design-details`),
          putApprove: (id, data) => this.put(`${this.tenant}/products/${id}/approve`, data)
      };
  }

    get proformaInvoices() {
      return{
        post: (orderId) => this.post(`${this.tenant}/proforma-invoices/order/${orderId}`),
        get: (orderId) => this.get(`${this.tenant}/proforma-invoices/order/${orderId}`)
      }
    }

    get companySettings() {
      return{
        get: (companyId) => this.get(`${this.tenant}/company-profiles/${companyId}/settings`),
        postProductionSettings: (companyId, data) => this.post(`${this.tenant}/company-profiles/${companyId}/settings/production-settings`, data)
      }
    }

    get blendSheets() {
      return {
        get: (criteria) => this.get(`${this.tenant}/blend-sheets${this.createODataQuery(criteria)}`),
        getById: (id) => this.get(`${this.tenant}/blend-sheets/${id}`),
        post: (data) => this.post(`${this.tenant}/blend-sheets`, data),
        updateBlendStandards: (id, data) => this.put(`${this.tenant}/blend-sheets/${id}`, data),
        updateAllocations: (id, data) => this.put(`${this.tenant}/blend-sheets/${id}/allocations`, data)
      }
    }

    get grades() {
      return {
        get: (criteria) => this.get(`${this.tenant}/grades${this.createODataQuery(criteria)}`),
        post: (data) => this.post(`${this.tenant}/grades`, data),
      }
    }

    get bom() {
      return {
        get: (criteria) => this.get(`${this.tenant}/boms${this.createODataQuery(criteria)}`),
        post:(data) => this.post(`${this.tenant}/boms`, data),
        put: (id, data) => this.put(`${this.tenant}/boms/${id}`, data),
        getById: (id) => this.get(`${this.tenant}/boms/${id}`),
        getVariations: (id, criteria) => this.get(`${this.tenant}/boms/${id}/variations${this.createODataQuery(criteria)}`)
      }
    }

    get facilities() {
      return {
        get: (criteria) => this.get(`${this.tenant}/warehouses/facilities${this.createODataQuery(criteria)}`),
        post:(data) => this.post(`${this.tenant}/warehouses/facilities`, data),
        put: (id, data) => this.put(`${this.tenant}/warehouses/facilities/${id}`, data),
        getById: (id) => this.get(`${this.tenant}/warehouses/facilities/${id}`),
      }
    }

    get warehouseLocations() {
      return {
        put: (id, data) => this.put(`${this.tenant}/warehouses/locations/${id}`, data)
      }
    }

    get items() {
      return {
        get: (criteria) => this.get(`${this.tenant}/items${this.createODataQuery(criteria)}`),
        post: (data) => this.post(`${this.tenant}/items`, data),
        put: (id, data) => this.put(`${this.tenant}/items/${id}`, data),
        getById: (id) => this.get(`${this.tenant}/items/${id}`),
        uploadImage: (id, data) => this.upload("PUT", `${this.tenant}/items/${id}/image`, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }),
        getImage: (id) => this.getFile(`${this.tenant}/items/${id}/image`, {
            responseType: 'blob'
        })
      };
    }

    get itemCategories() {
        return {
            get: (criteria) => this.get(`${this.tenant}/item-categories${this.createODataQuery(criteria)}`),
            getById: (id) => this.get(`${this.tenant}/item-categories/${id}`),
            post: (data) => this.post(`${this.tenant}/item-categories`, data),
            put: (id, data) => this.put(`${this.tenant}/item-categories/${id}`, data)
        };
    }

    get supplierItems() {
      return{
        get: (criteria) => this.get(`${this.tenant}/supplier-items${this.createODataQuery(criteria)}`),
        getById: (id) => this.get(`${this.tenant}/supplier-items/${id}`),
        post: (data) => this.post(`${this.tenant}/supplier-items`, data),
        put: (id, data) => this.put(`${this.tenant}/supplier-items/${id}`, data)
      }
    }

    get collectionNotes() {
      return {
        get: (criteria) => this.get(`${this.tenant}/warehouses/collections/notes${this.createODataQuery(criteria)}`),
        post:(data) => this.post(`${this.tenant}/warehouses/collections/notes`, data),
        put: (id) => this.put(`${this.tenant}/warehouses/collections/notes/${id}`),
        getById: (id) => this.get(`${this.tenant}/warehouses/collections/notes/${id}`),
        getCollectionNoteItemsById: (id) => this.get(`${this.tenant}/warehouses/collections/notes/${id}/items`),
        getCollectionNoteDocuments:(id) =>this.getFile(`${this.tenant}/warehouses/collections/notes/${id}/documents`),
        completeCollectionNote: (id) => this.put(`${this.tenant}/warehouses/collections/notes/${id}/complete`),
        completeCollectionNoteItem: (id, itemId) => this.put(`${this.tenant}/warehouses/collections/notes/${id}/item/${itemId}/complete`),
      }
    }

    get facilityLocations() {
      return {
        get: (facilityId, criteria) => this.get(`${this.tenant}/warehouses/facilities/${facilityId}/locations${this.createODataQuery(criteria)}`),
        post:(facilityId, data) => this.post(`${this.tenant}/warehouses/facilities/${facilityId}/locations`, data),
        put: (facilityId, id, data) => this.put(`${this.tenant}/warehouses/facilities/${facilityId}/locations/${id}`, data)
      }
    }

    get facilityZones() {
      return {
        get: (facilityId, criteria) => this.get(`${this.tenant}/warehouses/facilities/${facilityId}/zones${this.createODataQuery(criteria)}`),
        post:(facilityId, data) => this.post(`${this.tenant}/warehouses/facilities/${facilityId}/zones`, data),
        put: (facilityId, id, data) => this.put(`${this.tenant}/warehouses/facilities/${facilityId}/zones/${id}`, data)
      }
    }

    get workstations() {
      return {
        get: (criteria) => this.get(`${this.tenant}/workstations${this.createODataQuery(criteria)}`),
        getById: (id) => this.get(`${this.tenant}/workstations/${id}`),
        post: (data) => this.post(`${this.tenant}/workstations`, data),
        put: (id, data) => this.put(`${this.tenant}/workstations/${id}`, data),
      };
    }
    
    get workstationMachines() {
      return {
        get: (workstationId) => this.get(`${this.tenant}/workstations/${workstationId}/machines`),
        post: (workstationId, data) => this.post(`${this.tenant}/workstations/${workstationId}/machines`, data),
        put: (workstationId, id, data) => this.put(`${this.tenant}/workstations/${workstationId}/machines/${id}`, data),
      };
    }

    get purchaseOrders() {
      return {
        post: (data) => this.post(`${this.tenant}/purchase-orders/`, data)
      }
    }

    eligibleStandardCriteria = data => {
      const standardId = data.standardId ? `&standardId=${data.standardId}` : '';
      const customerId = data.customerId ? `&customerId=${data.customerId}` : '';
      let query = '';
      if(standardId) {
        query+= `${standardId}`;
      }
      if(customerId) {
        query+= `${customerId}`;
      }
      return query;
    }

    createODataQuery = criteria =>{
      criteria = criteria ?? {};
      const filter = criteria.filter ? `$filter=${criteria.filter}` : '';
      const select = criteria.select ? `$select=${criteria.select}` : '';
      const orderBy = criteria.orderBy ? `$orderby=${criteria.orderBy}` : '';
      const top = criteria.top > 0 ? `$top=${criteria.top}` : '';
      const expand = criteria.expand ? `$expand=${criteria.expand}` : '';
      const apply = criteria.apply ? `$apply=${criteria.apply}` : '';
      let query = '?';
      if (top) {
        query += `${top}&`
      }
      if (filter) {
        query += `${filter}&`
      }
      if (select) {
        query += `${select}&`
      }
      if (orderBy) {
        query += `${orderBy}&`
      }
      if(expand){
        query += `${expand}&`
      }
      if (apply) {
        query += `${apply}&`
      }
      return query;
    }
  
  }
  
  export default SmartFlowApiClient;