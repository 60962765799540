import { createSlice } from "@reduxjs/toolkit"
import ApiClientConnector from "src/api/ApiClientConnector"
import { convertToKeyValueList } from "src/utils/common"

const initialState = {
    vatTypeList: [],
    vatTypes: {},
    addressTypeList: [],
    addressTypes: {},
    bankAccountTypeList: [],
    bankAccountTypes: {},
    contactTypeList: [],
    contactTypes: {},
    unitOfMeasureTypeList: [],
    unitOfMeasureTypes: {},
    blendSheetStatusList: [],
    blendSheetStatusTypes: {},
    orderStatusList: [],
    orderStatusTypes: {},
    facilityTypeList: [],
    facilityTypes: {},
    stockItemTypeList: [],
    stockItemTypes: {},
    unitTypeList: [],
    unitTypes: {},
    productStatusList: [],
    productStatusTypes: {},
    packingTypeList: [],
    packingTypes: {},
    bomStatusList: [],
    bomStatusTypes: {},
    itemTypeList: [],
    itemTypes: {},
    timePeriodTypeList: [],
    timePeriodTypes: {}
}

export const TypeSlice = createSlice({
    name: "type",
    initialState,
    reducers: {
        setTypes(state, action) {
            switch(action.payload.type){
                case 'VatType':
                    state.vatTypeList = action.payload.response;
                    state.vatTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                case 'AddressType':
                    state.addressTypeList = action.payload.response;
                    state.addressTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                case 'BankAccountType':
                    state.bankAccountTypeList = action.payload.response;
                    state.bankAccountTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                case 'ContactType':
                    state.contactTypeList = action.payload.response;
                    state.contactTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                case 'UnitOfMeasureType':
                    state.unitOfMeasureTypeList = action.payload.response;
                    state.unitOfMeasureTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                case 'BlendSheetStatus':
                    state.blendSheetStatusList = action.payload.response;
                    state.blendSheetStatusTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                case 'OrderStatus':
                    state.orderStatusList = action.payload.response;
                    state.orderStatusTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                case 'FacilityType':
                    state.facilityTypeList = action.payload.response;
                    state.facilityTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                case 'StockItemType':
                    state.stockItemTypeList = action.payload.response;
                    state.stockItemTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                case 'UnitType':
                    state.unitTypeList = action.payload.response;
                    state.unitTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                case 'ProductStatus':
                    state.productStatusList = action.payload.response;
                    state.productStatusTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                case 'PackingType':
                    state.packingTypeList = action.payload.response;
                    state.packingTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                case 'BillOfMaterialStatus':
                    state.bomStatusList = action.payload.response;
                    state.bomStatusTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                case 'ItemType':
                    state.itemTypeList = action.payload.response;
                    state.itemTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                case 'TimePeriodType':
                    state.timePeriodTypeList = action.payload.response;
                    state.timePeriodTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                case 'ProcessRateType':
                    state.processRateTypeList = action.payload.response;
                    state.processRateTypes = convertToKeyValueList(action.payload.response, 'name');
                    break;
                default:
                    return;
            }
        }
    }
})

export const { setTypes } = TypeSlice.actions;

export const fetchTypes = (instance, type) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.types.get(type);
        dispatch(setTypes({response, type}));
        return response;
    } catch (error) {
        throw error;
    }
}

export default TypeSlice.reducer;