import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    machineList: [],
    selectedMachine: null,
};

export const WorkstationMachineSlice = createSlice({
    name: "workstationMachines",
    initialState,
    reducers: {
        getMachines: (state, action) => {
            state.machineList = action.payload;
        },
        addNewMachine: (state, action) => {
            state.machineList = [...state.machineList, action.payload];
        },
        updateMachineDetails: (state, action) => {
            var index = state.machineList.findIndex(i => i.id === action.payload.id);
            state.machineList[index] = {...action.payload};
        },
        setSelectedMachine: (state, action) => {
            state.selectedMachine = action.payload;
        },
    },
});

export const { getMachines, addNewMachine, updateMachineDetails, setSelectedMachine } = WorkstationMachineSlice.actions;

export const fetchMachines = (instance, workstationId) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.workstationMachines.get(workstationId);
        dispatch(getMachines(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export const addMachine = (instance, workstationId, data) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.workstationMachines.post(workstationId, data);
        dispatch(addNewMachine(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export const updateMachine = (instance, workstationId, id, data) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.workstationMachines.put(workstationId, id, data);
        dispatch(updateMachineDetails(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export default WorkstationMachineSlice.reducer;
