import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    workstationList: [],
    selectedWorkstation: null,
    selectedWorkstationId: null,
    isManageView: false,
    isDetailView: false,
};

export const WorkstationSlice = createSlice({
    name: "workstations",
    initialState,
    reducers: {
        getWorkstations: (state, action) => {
            state.workstationList = action.payload;
        },
        addNewWorkstation: (state, action) => {
            state.workstationList = [...state.workstationList, action.payload];
        },
        updateWorkstationDetails: (state, action) => {
            var index = state.workstationList.findIndex(i => i.id === action.payload.id);
            state.workstationList[index] = {...action.payload};
        },
        changeSelectedWorkstationId: (state, action) => {
            state.selectedWorkstationId = action.payload;
        },
        setSelectedWorkstation: (state, action) => {
            state.selectedWorkstation = action.payload;
        },
        changeDetailView: (state, action) => {
            state.isDetailView = action.payload;
        },
        changeManageView: (state, action) => {
            state.isManageView = action.payload;
        }
    }
})

export const { getWorkstations, addNewWorkstation, updateWorkstationDetails, changeSelectedWorkstationId, changeDetailView, changeManageView, setSelectedWorkstation } = WorkstationSlice.actions;

export const fetchWorkstations = (instance, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.workstations.get(criteria);
        dispatch(getWorkstations(response))
        return response;
    } catch (error) {
        throw error;
    }
}

export const addWorkstation = (instance, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.workstations.post(data);
        dispatch(addNewWorkstation(response));
        dispatch(setSelectedWorkstation(response));
        return response.id;
    } catch (error){
        throw error;
    }
}

export const updateWorkstation = (instance, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.workstations.put(data.id, data);
        dispatch(updateWorkstationDetails(response));
        dispatch(setSelectedWorkstation(response));
        return response.id;
    } catch (error){
        throw error;
    }
}

export const fetchWorkstation = (instance, id) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.workstations.getById(id);
        dispatch(setSelectedWorkstation(response));
        return response;
    } catch (error){
        throw error;
    }
}

export default WorkstationSlice.reducer;
