import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    purchaseRequestItemList: [],
    groupedRequestsItemList: [],
    purchaseOrderItemList: []
};

const groupBySupplier = (data) => 
    data.reduce((acc, item) => {
        (acc[item.supplierItemId] ??= []).push(item);
        return acc;
    }, {});

export const PurchaseRequestSlice = createSlice({
    name: 'purchase-request-items',
    initialState,
    reducers: {
        getPurchaseRequestItems: (state, action) => {
            const data = action.payload;
            state.purchaseRequestItemList = data;
            state.groupedRequestsItemList = groupBySupplier(data);
        },
        changeSupplier: (state, action) => {
            const data = action.payload;
            const updatedList = state.purchaseRequestItemList.map(item =>
                item.id === data.id
                    ? { ...item, supplierItem: data.supplierItem, supplierItemId: data.supplierItem.id, supplierId: data.supplierItem.supplierId }
                    : item
            );
            state.purchaseRequestItemList = updatedList;
            state.groupedRequestsItemList = groupBySupplier(updatedList);
        },
        setPurchaseOrderItems: (state) => {
            var list = [];
            Object.values(state.groupedRequestsItemList).forEach(i => {
                list.push({
                    supplierItemId: i[0].id,
                    itemId: i[0].itemId,
                    item: i[0].item,
                    supplierId: i[0].supplierItem.supplierId,
                    supplier: i[0].supplierItem.supplier,
                    price: i[0].supplierItem.price,
                    requiredQuantity: i.reduce((total, j) => total + j.quantity, 0),
                    quantity: i.reduce((total, j) => total + j.quantity, 0),
                    neededByDate: i.reduce((min, item) => { return new Date(item.neededByDate) < new Date(min.neededByDate) ? item : min }).neededByDate,
                    purchaseRequestItems: i.map(item => item.id)
                });
            });
            state.purchaseOrderItemList = list;
        },
        changeQuantiry: (state, action) => {
            var updaedList = state.purchaseOrderItemList.map(item => 
                item.supplierItemId === action.payload.supplierItemId
                    ? { ...item, quantity: action.payload.quantity }
                    : item
            );
            state.purchaseOrderItemList = updaedList;
        },
        clearAllState: (state) => {
            state.purchaseRequestItemList = [];
            state.groupedRequestsItemList = [];
            state.purchaseOrderItemList = [];
        }
    }
});

export const { getPurchaseRequestItems, changeSupplier, setPurchaseOrderItems, changeQuantiry, clearAllState } = PurchaseRequestSlice.actions;

export const fetchPurchaseRequestItems = (instance, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.purchaseRequestItems.get(criteria);
        dispatch(getPurchaseRequestItems(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export default PurchaseRequestSlice.reducer;