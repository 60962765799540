import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    purchaseOrder: [],
    purchaseOrderList: []
};

export const PurchaseOrderSlice = createSlice({
    name: 'purchase-orders',
    initialState,
    reducers: {
        setPurchaseOrder: (state, action) => {
            state.purchaseOrder = action.payload;
        },
        setPurchaseOrders: (state, action) => {
            state.purchaseOrderList = action.payload;
        },
        clearAllState: (state) => {
            state.purchaseOrder = [];
            state.purchaseOrderList = [];
        }
    }
});

export const { setPurchaseOrder, setPurchaseOrders, clearAllState } = PurchaseOrderSlice.actions;

export const addPurchaseOrders = (instance, data) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.purchaseOrders.post(data);
        dispatch(setPurchaseOrders(response));
        return response;
    } catch (error) {
        throw error;
    }
}

export default PurchaseOrderSlice.reducer;