import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    ordersList: [],
    ordersItems:[],
    selectedOrder: null,
    isViewOrderList: true,
    poNumbersList: [],
    isViewOrderDetailsList: false,
    orderDetailsList: []
};

export const OrderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        getOrders: (state, action) => {
            state.ordersList = action.payload;
        },
        addNewOrder: (state, action) => {
            var data = {
                id: action.payload.Id,
                orderNumber: action.payload.OrderNumber,
                purchaseOrderNumber: action.payload.PurchaseOrderNumber,
            }
            state.ordersList = [...state.ordersList, data];
            state.selectedOrder = action.payload;
        },
        updateExistsOrder: (state, action) => {
            var index = state.ordersList.findIndex(i => i.id === action.payload.id);
            state.ordersList[index] = {...action.payload};
            state.selectedOrder = action.payload;
        },
        addOrdersItem: (state, action)=> {
            var items = [...state.ordersItems, action.payload];
            state.ordersItems = items.sort((i1,i2)=>{return i1.lineNumber-i2.lineNumber})
        },
        clearOrdersItem:(state)=> {
            state.ordersItems = [];
        },
        setSelectedOrder: (state, action) => {
            state.selectedOrder = action.payload;
            if(action.payload != null) {
                state.orderDetailsList = action.payload?.details ?? [];
            }
        },
        setIsViewOrderList: (state, action) => {
            state.isViewOrderList = action.payload;
        },
        getPoNumberList: (state, action) => {
            state.poNumbersList = action.payload;
        },
        clearAllState: (state) => {
            state = initialState;
        },
        setIsViewOrderDetailsList: (state, action) => {
            state.isViewOrderDetailsList = action.payload;
        }
    }
})

export const { getOrders, addNewOrder, addOrdersItem, clearOrdersItem, setSelectedOrder, setIsViewOrderList, setSelectedOrderId, getPoNumberList, clearAllState, setIsViewOrderDetailsList } = OrderSlice.actions;

export const fetchOrders = (instance, criteria, isSetState = true) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.orders.get(criteria);
        isSetState && dispatch(getOrders(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const fetchPoNumbers = (instance, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.orders.get(criteria);
        dispatch(getPoNumberList(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const addOrder = (instance, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.orders.post(data);
        dispatch(addNewOrder(response));
        return response;
    } catch (error){
        throw error;
    }
}

export const confirmOrder = (instance, id) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.orders.confirmOrder(id);
        return response;
    } catch (error){
        throw error;
    }
}

export const fetchOrder = (instance, id, customer, standard, includeBuyerAddress, includeConsigneeAddress, includeConsigneeBankAccount, includeNotifyContact) => async () => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.orders.getById(id, customer, standard, includeBuyerAddress, includeConsigneeAddress, includeConsigneeBankAccount, includeNotifyContact);
        return response;
    } catch (error){
        throw error;
    }
}

export const updateOrder = (instance, data) => async () => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.orders.put(data.id, data);
        return response;
    } catch (error){
        throw error;
    }
}

export default OrderSlice.reducer;